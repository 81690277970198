"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _usercrud = require("@/api/usercrud");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CarriageConfig',
  data: function data() {
    return {
      temp: {
        brand_name: '',
        card_image: '',
        brand_icon: '',
        title: '',
        rebate: ''
      },
      rules: {
        money: [{
          required: true,
          message: '请输入运费',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getCarriage();
  },
  methods: {
    getCarriage: function getCarriage() {
      var _this = this;

      this.listLoading = true;
      (0, _usercrud.detail)("vip_config", null).then(function (response) {
        //console.log(response);
        _this.temp = response.data;
      });
    },
    updateData: function updateData() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = _this2.temp;
          (0, _usercrud.edit)("vip_config", tempData).then(function () {
            _this2.$notify({
              title: '操作成功',
              message: '修改成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    }
  }
};
exports.default = _default;