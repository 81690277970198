"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

var _usercrud = require("@/api/usercrud");

var _utils = require("@/utils");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _validate = require("@/utils/validate");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var calendarTypeOptions = [{
  key: 'CN',
  display_name: 'China'
}, {
  key: 'US',
  display_name: 'USA'
}, {
  key: 'JP',
  display_name: 'Japan'
}, {
  key: 'EU',
  display_name: 'Eurozone'
}];
var calendarTypeKeyValue = calendarTypeOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.display_name;
  return acc;
}, {});
var _default = {
  name: 'CouponTable',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: 'success',
        0: 'danger'
      };
      return statusMap[status];
    },
    typeFilter: function typeFilter(type) {
      return calendarTypeKeyValue[type];
    }
  },
  data: function data() {
    var validateNumeric = function validateNumeric(rule, value, callback) {
      if (!(0, _validate.isNumeric)(value)) {
        callback(new Error('只能输入数字(最多保留两位小数)'));
        console.log('错误');
      } else {
        callback();
      }
    };

    return {
      genable: true,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      roles: null,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      importanceOptions: [1, 2, 3],
      calendarTypeOptions: calendarTypeOptions,
      sortOptions: [{
        label: 'ID Ascending',
        key: '+id'
      }, {
        label: 'ID Descending',
        key: '-id'
      }],
      statusOptions: ['published', 'draft', 'deleted'],
      showReviewer: false,
      temp: {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        type: '',
        status: 'published',
        role_id: ''
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      dialogPvVisible: false,
      pvData: [],
      rules: {
        coupon_title: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        begin_time: [{
          required: true,
          message: '请选择开始时间',
          trigger: 'blur'
        }],
        end_time: [{
          required: true,
          message: '请选择结束时间',
          trigger: 'blur'
        }],
        amount: [{
          validator: validateNumeric,
          trigger: 'blur'
        }],
        min_amount: [{
          validator: validateNumeric,
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _usercrud.datagrid)('coupon', this.listQuery).then(function (response) {
        _this.list = response.data.rows;
        _this.total = response.data.total;
        _this.listLoading = false; // Just to simulate the time of the request

        /* setTimeout(() => {
          this.listLoading = false
        }, 1.5 * 1000)*/
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleModifyStatus: function handleModifyStatus(row, status) {
      this.$message({
        message: '操作Success',
        type: 'success'
      });
      row.status = status;
    },
    sortChange: function sortChange(data) {
      var prop = data.prop,
          order = data.order;

      if (prop === 'id') {
        this.sortByID(order);
      }
    },
    sortByID: function sortByID(order) {
      if (order === 'ascending') {
        this.listQuery.sort = '+id';
      } else {
        this.listQuery.sort = '-id';
      }

      this.handleFilter();
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        status: 'published',
        type: ''
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.resetTemp();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    handleChangeEnable: function handleChangeEnable(row) {
      var _this3 = this;

      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.2)'
      });
      var params = {
        id: row.id,
        field: 'is_valid',
        value: row.is_valid
      };
      (0, _usercrud.property)('coupon', params).then(function (response) {
        loading.close();

        _this3.$notify({
          title: '操作成功',
          message: '修改成功',
          type: 'success',
          duration: 2000
        });
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _usercrud.add)('coupon', _this4.temp).then(function (response) {
            // console.log(response);
            _this4.temp.id = response.data.id;

            _this4.list.unshift(_this4.temp);

            _this4.dialogFormVisible = false;

            _this4.$notify({
              title: '操作成功',
              message: '添加成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;

      (0, _usercrud.detail)('coupon', row.id).then(function (response) {
        // console.log(response);
        _this5.temp = response.data;
      });
      this.temp = Object.assign({}, row); // copy obj

      this.temp.timestamp = new Date(this.temp.timestamp);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = Object.assign({}, _this6.temp);
          tempData.timestamp = +new Date(tempData.timestamp); // change Thu Nov 30 2017 16:41:05 GMT+0800 (CST) to 1512031311464

          (0, _usercrud.edit)('coupon', tempData).then(function () {
            var _iterator = (0, _createForOfIteratorHelper2.default)(_this6.list),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;

                if (v.id === _this6.temp.id) {
                  var index = _this6.list.indexOf(v);

                  _this6.list.splice(index, 1, _this6.temp);

                  break;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }

            _this6.dialogFormVisible = false;

            _this6.$notify({
              title: '操作成功',
              message: '修改成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this7 = this;

      var param = {
        id: row.id
      };
      this.$confirm('删除操作不可恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _usercrud.del)('coupon', param).then(function (response) {
          console.log(response);

          _this7.$notify({
            title: '操作成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });

          var index = _this7.list.indexOf(row);

          _this7.list.splice(index, 1);
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this8 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        var filterVal = ['timestamp', 'title', 'type', 'importance', 'status'];

        var data = _this8.formatJson(filterVal, _this8.list);

        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: 'table-list'
        });
        _this8.downloadLoading = false;
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return (0, _utils.parseTime)(v[j]);
          } else {
            return v[j];
          }
        });
      });
    },
    getSortClass: function getSortClass(key) {
      var sort = this.listQuery.sort;
      return sort === "+".concat(key) ? 'ascending' : sort === "-".concat(key) ? 'descending' : '';
    }
  }
};
exports.default = _default;