"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

// import { getToken } from '@/utils/auth'
// create an axios instance
var loading = null;

var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout

}); // request interceptor


service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (config.method === 'post') {
    loading = _elementUi.Loading.service({});
  }

  if (_store.default.getters.token) {// let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    //  config.headers['X-Token'] = getToken()
  }

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;

  if (loading) {
    loading.close();
  } // if the custom code is not 20000, it is judged as an error.


  if (res.code !== 20000) {
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === 50008 || res.code === 50012 || res.code === 50014 || res.code === 30401) {
      // to re-login
      _elementUi.MessageBox.confirm('未登录或登录超时', '操作提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    } else {
      (0, _elementUi.Message)({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      });
    }

    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug

  if (loading) {
    loading.close();
  }

  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;