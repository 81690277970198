"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _userlayout = _interopRequireDefault(require("@/userlayout"));

/** When your routing table is too long, you can split it into small modules**/
var marketRouter = {
  path: '/user_market',
  component: _userlayout.default,
  name: 'User',
  meta: {
    title: '营销活动',
    icon: 'market',
    roletype: 'user'
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/market/index'));
      });
    },
    name: 'UserIndex',
    meta: {
      title: '优惠券管理',
      noCache: true,
      roletype: 'user'
    }
  }, {
    path: 'seckill',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/market/seckill'));
      });
    },
    name: 'UserIndex',
    hidden: true,
    meta: {
      title: '秒杀',
      noCache: true,
      roletype: 'user'
    }
  }]
};
var _default = marketRouter;
exports.default = _default;