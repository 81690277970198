"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _usercrud = require("@/api/usercrud");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
// secondary package based on el-pagination
var _default = {
  name: 'ProgramTable',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      genable: true,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      roles: null,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      showReviewer: false,
      temp: {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        type: '',
        status: 'published',
        role_id: ''
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      dialogPvVisible: false,
      pvData: [],
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        }, {
          type: 'email',
          message: '邮箱格式错误',
          trigger: 'blur'
        }]
      },
      downloadLoading: false,
      loading: null
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _usercrud.datagrid)('app_program', this.listQuery).then(function (response) {
        _this.list = response.data.rows;
        _this.total = response.data.total;
        _this.listLoading = false; // Just to simulate the time of the request

        /* setTimeout(() => {
          this.listLoading = false
        }, 1.5 * 1000)*/
      });
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        status: 'published',
        type: ''
      };
    },
    undoAudit: function undoAudit(row) {
      var _this2 = this;

      var url = "/user/app_program/undo_audit";
      (0, _usercrud.common)(url, {}, 'post').then(function (response) {
        row.status = 4;
        row.status_text = '审核撤回';

        _this2.loading.close();

        _this2.$notify({
          title: '操作成功',
          message: '审核已撤回',
          type: 'success',
          duration: 2000
        });
      });
    },
    auditStatus: function auditStatus(row) {
      var _this3 = this;

      var url = "/user/app_program/audit_status";
      (0, _usercrud.common)(url, {
        id: row.id
      }).then(function (response) {
        row.status = response.data.status;
        row.status_text = response.data.status_text;

        _this3.$notify({
          title: '操作成功',
          message: '状态已更新',
          type: 'success',
          duration: 2000
        });
      });
    }
  }
};
exports.default = _default;