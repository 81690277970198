"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _validate = require("@/utils/validate");

var _SocialSignin = _interopRequireDefault(require("./components/SocialSignin"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  components: {
    SocialSign: _SocialSignin.default
  },
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.validUsername)(value)) {
        callback(new Error('账号错误(由字母数字组成，长度5-16)'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('密码长度不得少于6位'));
      } else {
        callback();
      }
    };

    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      logo: process.env.VUE_APP_BASE_API + 'images/logo.png'
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;

        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {// window.addEventListener('storage', this.afterQRScan)
  },
  mounted: function mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  destroyed: function destroyed() {// window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          console.log('登录');

          _this2.$store.dispatch('admin/login', _this2.loginForm).then(function () {
            _this2.$router.push({
              path: _this2.redirect || '/admin_index',
              query: _this2.otherQuery
            });

            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }

        return acc;
      }, {});
    } // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }

  }
};
exports.default = _default;