"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _crud = require("@/api/crud");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _utils = require("@/utils");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
// secondary package based on el-pagination
var calendarTypeOptions = [{
  key: 'CN',
  display_name: 'China'
}, {
  key: 'US',
  display_name: 'USA'
}, {
  key: 'JP',
  display_name: 'Japan'
}, {
  key: 'EU',
  display_name: 'Eurozone'
}]; // arr to obj, such as { CN : "China", US : "USA" }

var calendarTypeKeyValue = calendarTypeOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.display_name;
  return acc;
}, {});
var _default = {
  name: 'OrderTable',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: 'success',
        0: 'danger'
      };
      return statusMap[status];
    },
    typeFilter: function typeFilter(type) {
      return calendarTypeKeyValue[type];
    }
  },
  data: function data() {
    return {
      genable: true,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      roles: null,
      listQuery: {
        page: 1,
        limit: 20,
        importance: undefined,
        title: undefined,
        type: undefined,
        order_status: undefined,
        service_status: undefined
      },
      importanceOptions: [1, 2, 3],
      calendarTypeOptions: calendarTypeOptions,
      sortOptions: [{
        label: 'ID Ascending',
        key: '+id'
      }, {
        label: 'ID Descending',
        key: '-id'
      }],
      statusOptions: ['published', 'draft', 'deleted'],
      showReviewer: false,
      temp: {
        order: {},
        service_status_text: '',
        items: [],
        refund: {}
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '详情'
      },
      dialogPvVisible: false,
      pvData: [],
      downloadLoading: false,
      status_arr: [{
        text: '待支付',
        value: 1
      }, {
        text: '待发货',
        value: 2
      }, {
        text: '待收货',
        value: 3
      }, {
        text: '交易完成',
        value: 4
      }, {
        text: '已取消',
        value: 6
      }],
      service_status_arr: [{
        text: '申请退款',
        value: 1
      }, {
        text: '商家同意退款',
        value: 2
      }, {
        text: '商家拒绝退款',
        value: 3
      }, {
        text: '商家确认收货 ',
        value: 4
      }, {
        text: '退款中',
        value: 5
      }, {
        text: '退款成功',
        value: 6
      }, {
        text: '已取消',
        value: 7
      }]
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _crud.datagrid)('order', this.listQuery).then(function (response) {
        _this.list = response.data.rows;
        _this.total = response.data.total;
        _this.listLoading = false; // Just to simulate the time of the request

        /* setTimeout(() => {
          this.listLoading = false
        }, 1.5 * 1000)*/
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleModifyStatus: function handleModifyStatus(row, status) {
      this.$message({
        message: '操作Success',
        type: 'success'
      });
      row.status = status;
    },
    sortChange: function sortChange(data) {
      var prop = data.prop,
          order = data.order;

      if (prop === 'id') {
        this.sortByID(order);
      }
    },
    sortByID: function sortByID(order) {
      if (order === 'ascending') {
        this.listQuery.sort = '+id';
      } else {
        this.listQuery.sort = '-id';
      }

      this.handleFilter();
    },
    resetTemp: function resetTemp() {
      this.temp = {
        order: {},
        items: []
      };
    },
    payQuery: function payQuery(row) {
      var _this2 = this;

      (0, _crud.common)('/admin/order/pay_query', {
        id: row.order.id
      }, 'get').then(function (response) {
        console.log(response);
        _this2.temp.order_status = response.data.order_status;
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this3 = this;

      (0, _crud.detail)('order', row.id).then(function (response) {
        console.log(response);
        _this3.temp = response.data;
      }); // this.temp = Object.assign({}, row) // copy obj
      // this.temp.timestamp = new Date(this.temp.timestamp)

      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        var filterVal = ['timestamp', 'title', 'type', 'importance', 'status'];

        var data = _this4.formatJson(filterVal, _this4.list);

        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: 'table-list'
        });
        _this4.downloadLoading = false;
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return (0, _utils.parseTime)(v[j]);
          } else {
            return v[j];
          }
        });
      });
    },
    getSortClass: function getSortClass(key) {
      var sort = this.listQuery.sort;
      return sort === "+".concat(key) ? 'ascending' : sort === "-".concat(key) ? 'descending' : '';
    }
  }
};
exports.default = _default;