"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _userlayout = _interopRequireDefault(require("@/userlayout"));

/** When your routing table is too long, you can split it into small modules**/
var shopRouter = {
  path: '/user_shop',
  component: _userlayout.default,
  name: 'User',
  meta: {
    title: '商城管理',
    icon: 'shopping',
    roletype: 'user'
  },
  children: [{
    path: 'consumer',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/shop/consumer'));
      });
    },
    name: 'UserIndex',
    meta: {
      title: '客户管理',
      noCache: true,
      roletype: 'user'
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/shop/order'));
      });
    },
    name: 'UserIndex',
    meta: {
      title: '订单管理',
      noCache: true,
      roletype: 'user'
    }
  }]
};
var _default = shopRouter;
exports.default = _default;