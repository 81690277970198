var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c(
          "el-form",
          {
            ref: "dataForm",
            staticStyle: { width: "600px" },
            attrs: {
              rules: _vm.rules,
              model: _vm.temp,
              "label-position": "right",
              "label-width": "150px"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "小程序名称：" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.temp.app_title,
                    callback: function($$v) {
                      _vm.$set(_vm.temp, "app_title", $$v)
                    },
                    expression: "temp.app_title"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "小程序LOGO：" } },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      action: _vm.uploadUrl,
                      "show-file-list": false,
                      data: _vm.uploadParams
                    }
                  },
                  [
                    _vm.temp.img_data
                      ? _c("img", {
                          staticClass: "avatar",
                          attrs: { src: _vm.temp.img_data }
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon"
                        })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "小程序描述：", type: "textarea", rows: 2 } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.temp.app_desc,
                    callback: function($$v) {
                      _vm.$set(_vm.temp, "app_desc", $$v)
                    },
                    expression: "temp.app_desc"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.updateData()
                      }
                    }
                  },
                  [_vm._v("\n          确定\n        ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }