"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueCountTo = _interopRequireDefault(require("vue-count-to"));

var _echarts = _interopRequireDefault(require("echarts"));

var _crud = require("@/api/crud");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
require('echarts/theme/macarons'); // echarts theme


var _default = {
  name: 'Dashboard',
  components: {
    CountTo: _vueCountTo.default
  },
  data: function data() {
    return {
      index_data: {
        consumer_count: 0,
        order_count: 0,
        order_amount: 0,
        app_count: 0
      },
      chart_title: [],
      chart_data: []
    };
  },
  mounted: function mounted() {
    this.getOrderData();
  },
  created: function created() {
    this.getIndexData();
  },
  methods: {
    getIndexData: function getIndexData() {
      var _this = this;

      this.listLoading = true;
      (0, _crud.common)('/admin/home/index', {}).then(function (response) {
        _this.index_data = response.data;
        _this.listLoading = false;
      });
    },
    getOrderData: function getOrderData() {
      var _this2 = this;

      this.listLoading = true;
      (0, _crud.common)('/admin/home/order', {}).then(function (response) {
        _this2.chart_title = response.data.title;
        _this2.chart_data = response.data.data;
        _this2.listLoading = false;

        _this2.drawLine();
      });
    },
    drawLine: function drawLine() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = _echarts.default.init(this.$refs.chart, 'macarons'); // 绘制图表


      myChart.setOption({
        title: {
          text: '近7天订单数量'
        },
        tooltip: {},
        xAxis: {
          data: this.chart_title
        },
        yAxis: {},
        series: [{
          name: '订单数量',
          type: 'line',
          data: this.chart_data
        }]
      });
    }
  }
};
exports.default = _default;