var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container flex flex-cm" }, [
    _c(
      "div",
      {
        staticClass: "title-container",
        staticStyle: { "text-align": "center", padding: "15px" }
      },
      [
        _c("img", { attrs: { src: _vm.logo } }),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-top": "15px" } }, [
          _vm._v("登录中...")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }