"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

var _userauth = require("@/utils/userauth");

var _router = require("@/router");

var state = {
  usertoken: (0, _userauth.getUserToken)(),
  userroles: []
};
var mutations = {
  SET_USER_TOKEN: function SET_USER_TOKEN(state, token) {
    state.usertoken = token;
  },
  SET_USER_ROLES: function SET_USER_ROLES(state, roles) {
    state.userroles = roles;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      (0, _user.tokenLogin)(userInfo).then(function (response) {
        var data = response.data;
        commit('SET_USER_TOKEN', data.token);
        (0, _userauth.setUserToken)(data.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)(state.usertoken).then(function (response) {
        var data = response.data;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        var roles = data.roles; // roles must be a non-empty array

        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!');
        }

        commit('SET_USER_ROLES', roles);
        console.log('设置的userroles');
        console.log(roles);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state,
        dispatch = _ref3.dispatch;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)(state.usertoken).then(function () {
        commit('SET_USER_TOKEN', '');
        commit('SET_USER_ROLES', []);
        (0, _userauth.removeUserToken)();
        (0, _router.resetRouter)(); // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485

        dispatch('tagsView/delAllViews', null, {
          root: true
        });
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_USER_TOKEN', '');
      commit('SET_USER_ROLES', []);
      (0, _userauth.removeUserToken)();
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;