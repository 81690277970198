"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _userauth = require("@/utils/userauth");

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'UserLogin',
  data: function data() {
    return {
      loginForm: {},
      loading: false,
      redirect: undefined,
      logo: process.env.VUE_APP_BASE_API + 'images/logo.png'
    };
  },

  /* watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.loginForm = {
            token: query.token,
            platFrom: query.platFrom,
            _h_: query._h_,
            _t_: query._t_,
            uid: query.uid,
            debug: query.debug,
            api_token: getUserToken()
          }
        }
      },
      immediate: true
    }
  },*/
  created: function created() {
    var query = this.$route.query;

    if (query) {
      this.redirect = query.redirect;
      this.loginForm = {
        token: query.token,
        platFrom: query.platFrom,
        _h_: query._h_,
        _t_: query._t_,
        uid: query.uid,
        debug: query.debug,
        api_token: (0, _userauth.getUserToken)()
      };
    }

    this.handleLogin();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {// window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    handleLogin: function handleLogin() {
      var _this = this;

      if (!this.loginForm.token) {
        this.$notify({
          title: '登录失败',
          message: '没有token',
          type: 'success',
          duration: 2000
        }); // this.$router.push({ path: '/auth_fail' })

        return;
      }

      this.loading = true;
      this.$store.dispatch('user/login', this.loginForm).then(function () {
        console.log('登录成功');

        _this.$router.push({
          path: _this.redirect || '/user_miniapp/index'
        });

        _this.loading = false;
      }).catch(function () {
        _this.$router.push({
          path: '/auth_fail'
        });

        _this.loading = false;
      });
    }
  }
};
exports.default = _default;