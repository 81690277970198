"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules**/
var adminShopRouter = {
  path: '/admin_shop',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'admin_shop',
  meta: {
    title: '商城管理',
    icon: 'market',
    roletype: 'admin'
  },
  children: [{
    path: 'miniapp',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/miniapp/index'));
      });
    },
    name: 'RoleManage',
    meta: {
      title: '小程序管理',
      noCache: true,
      authority: 'admin.miniapp.datagrid',
      roletype: 'admin'
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/index'));
      });
    },
    name: 'RoleManage',
    meta: {
      title: '订单管理',
      noCache: true,
      authority: 'admin.order.datagrid',
      roletype: 'admin'
    }
  }, {
    path: 'consumer',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/consumer/index'));
      });
    },
    name: 'AdminManage',
    meta: {
      title: '客户管理',
      noCache: true,
      authority: 'admin.consumer.datagrid',
      roletype: 'admin'
    }
  }]
};
var _default = adminShopRouter;
exports.default = _default;