"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _usercrud = require("@/api/usercrud");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'UserIndex',
  data: function data() {
    return {
      app_info: [],
      auth_url: '',
      qrcode: null,
      online_qrcode: null,
      domain: process.env.VUE_APP_BASE_API,
      app_program: null,
      unbind_status: false
    };
  },
  created: function created() {
    var _this = this;

    var auth_code = this.$route.query.auth_code;

    if (auth_code) {
      (0, _usercrud.common)('/user/app_info/callback', {
        auth_code: auth_code
      }).then(function (response) {
        _this.app_info = response.data.app_info;
        _this.auth_url = response.data.url;
      });
    } else {
      (0, _usercrud.common)('/user/app_info', {}).then(function (response) {
        _this.app_info = response.data.app_info;
        _this.auth_url = response.data.url;
        _this.qrcode = response.data.qrcode;
        _this.online_qrcode = response.data.online_qrcode;
        _this.app_program = response.data.app_program;

        if (!_this.app_info && !_this.unbind_status) {
          window.top.location.href = _this.auth_url;
        }
      });
    }
  },
  methods: {
    miniappCommit: function miniappCommit() {
      var _this2 = this;

      (0, _usercrud.common)('/user/miniapp/commit', {}, 'post').then(function (response) {
        _this2.app_program = response.data.app_program;
        _this2.qrcode = response.data.qrcode;
        _this2.online_qrcode = null;

        _this2.$notify({
          title: '操作成功',
          message: '代码已提交',
          type: 'success',
          duration: 2000
        });
      });
    },
    miniappAudit: function miniappAudit() {
      var _this3 = this;

      (0, _usercrud.common)('/user/miniapp/audit', {}, 'post').then(function (response) {
        _this3.app_program.status = 1;
        _this3.app_program.status_text = '待审核';

        _this3.$notify({
          title: '操作成功',
          message: '已提交审核',
          type: 'success',
          duration: 2000
        });
      });
    },
    undoAudit: function undoAudit(row) {
      var _this4 = this;

      var url = "/user/app_program/undo_audit";
      (0, _usercrud.common)(url, {}, 'post').then(function (response) {
        _this4.app_program.status = 4;
        _this4.app_program.status_text = '审核撤回';

        _this4.loading.close();

        _this4.$notify({
          title: '操作成功',
          message: '审核已撤回',
          type: 'success',
          duration: 2000
        });
      });
    },
    miniappRelease: function miniappRelease() {
      var _this5 = this;

      (0, _usercrud.common)('/user/miniapp/release', {}, 'post').then(function (response) {
        _this5.app_program.status = 1;
        _this5.app_program.status_text = '待审核';

        _this5.$notify({
          title: '操作成功',
          message: '已提交审核',
          type: 'success',
          duration: 2000
        });
      });
    },
    unbind: function unbind() {
      var _this6 = this;

      this.$confirm('该解绑操作仅做删除，解除授权请前往微信公众平台！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _usercrud.common)('/user/app_info/unbind', {}, 'post').then(function (response) {
          _this6.app_info = null;
          _this6.unbind_status = true;

          _this6.$notify({
            title: '操作成功',
            message: '解绑成功',
            type: 'success',
            duration: 2000
          });
        });
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: '已取消解绑操作'
        });
      });
    }
  }
};
exports.default = _default;