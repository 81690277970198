"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datagrid = datagrid;
exports.datalist = datalist;
exports.del = del;
exports.add = add;
exports.detail = detail;
exports.edit = edit;
exports.property = property;
exports.common = common;

var _request = _interopRequireDefault(require("@/utils/request"));

var _auth = require("@/utils/auth");

// get token from cookie
function datagrid(entity, query) {
  var url = 'admin/' + entity + '/datagrid';
  query.api_token = (0, _auth.getToken)();
  return (0, _request.default)({
    url: url,
    method: 'get',
    params: query
  });
}

function datalist(entity, query) {
  var url = 'admin/' + entity + '/datalist';
  query.api_token = (0, _auth.getToken)();
  return (0, _request.default)({
    url: url,
    method: 'get',
    params: query
  });
}

function del(entity, data) {
  var url = 'admin/' + entity + '/delete';
  data.api_token = (0, _auth.getToken)();
  return (0, _request.default)({
    url: url,
    method: 'post',
    data: data
  });
}

function add(entity, data) {
  var url = "admin/".concat(entity, "/add");
  data.api_token = (0, _auth.getToken)();
  return (0, _request.default)({
    url: url,
    method: 'post',
    data: data
  });
}

function detail(entity, id) {
  var url = "admin/".concat(entity, "/detail");
  var data = {
    id: id,
    api_token: (0, _auth.getToken)()
  };
  return (0, _request.default)({
    url: url,
    method: 'get',
    params: data
  });
}

function edit(entity, data) {
  var url = "admin/".concat(entity, "/edit");
  data.api_token = (0, _auth.getToken)();
  return (0, _request.default)({
    url: url,
    method: 'post',
    data: data
  });
}

function property(entity, data) {
  var url = "admin/".concat(entity, "/property");
  data.api_token = (0, _auth.getToken)();
  return (0, _request.default)({
    url: url,
    method: 'post',
    data: data
  });
}

function common(url, data) {
  var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'get';
  data.api_token = (0, _auth.getToken)();
  return (0, _request.default)({
    url: url,
    method: method,
    params: data
  });
}