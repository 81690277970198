"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _crud = require("@/api/crud");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'adminUpdatePassword',
  data: function data() {
    return {
      temp: {
        oldpassword: null,
        newpassword: null,
        repassword: null
      },
      rules: {
        money: [{
          required: true,
          message: '请输入运费',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  created: function created() {},
  methods: {
    updatePassword: function updatePassword() {
      var _this = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = _this.temp;
          var url = "admin/update_password";
          (0, _crud.common)(url, tempData, 'post').then(function () {
            _this.$notify({
              title: '操作成功',
              message: '修改成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    }
  }
};
exports.default = _default;