"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _router = require("@/router");

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, resources, roletype, route) {
  if (roletype == 'admin') {
    console.log("判断的路由" + route.path);
    var r = true;

    if (route.meta && route.meta.authority != undefined) {
      //return roles.some(role => route.meta.roles.includes(role));
      r = resources.includes(route.meta.authority) && route.meta.roletype == roletype;
    }

    if (route.meta && route.meta.roletype != 'admin') {
      r = false;
    }

    return r;
  } else {
    if (route.meta) {
      console.log(route.meta);
      return route.meta.roletype == roletype;
    }

    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */


function filterAsyncRoutes(routes, roles, resources, roletype) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);

    if (hasPermission(roles, resources, roletype, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles, resources, roletype);
      }

      res.push(tmp);
    }
  });
  return res;
}

var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, params) {
    var commit = _ref.commit;
    var roles = params.roles,
        resources = params.resources,
        roletype = params.roletype;
    return new Promise(function (resolve) {
      var accessedRoutes; //if (roles.includes('admin')) {
      //  accessedRoutes = asyncRoutes || []
      //} else {

      accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, roles, resources, roletype); //}

      commit('SET_ROUTES', accessedRoutes);
      console.log("可以进入的路由");
      console.log(accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;