"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserToken = getUserToken;
exports.setUserToken = setUserToken;
exports.removeUserToken = removeUserToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var TokenKey = 'User-Token';

function getUserToken() {
  return _jsCookie.default.get(TokenKey);
}

function setUserToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function removeUserToken() {
  return _jsCookie.default.remove(TokenKey);
}