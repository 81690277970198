"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _userlayout = _interopRequireDefault(require("@/userlayout"));

/** When your routing table is too long, you can split it into small modules**/
var configRouter = {
  path: '/user_config',
  component: _userlayout.default,
  name: 'UserConfig',
  meta: {
    title: '系统配置',
    icon: 'setting',
    roletype: 'user'
  },
  children: [{
    path: 'base',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/config/base'));
      });
    },
    name: 'UserConfigBase',
    hidden: true,
    meta: {
      title: '基础信息',
      noCache: true,
      roletype: 'user'
    }
  }, {
    path: 'carriage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/config/carriage'));
      });
    },
    name: 'UserConfigCarriage',
    meta: {
      title: '运费设置',
      noCache: true,
      roletype: 'user'
    }
  }, {
    path: 'pay_config',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/config/pay_config'));
      });
    },
    name: 'UserConfigWechatPay',
    meta: {
      title: '微信支付配置',
      noCache: true,
      roletype: 'user'
    }
  }, {
    path: 'vip',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/config/vip'));
      });
    },
    name: 'UserConfigVip',
    hidden: true,
    meta: {
      title: '会员卡',
      noCache: true,
      roletype: 'user'
    }
  }]
};
var _default = configRouter;
exports.default = _default;