var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              staticClass: "query-form",
              attrs: { inline: true, model: _vm.listQuery }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "订单号" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleFilter($event)
                      }
                    },
                    model: {
                      value: _vm.listQuery.searchValue,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "searchValue", $$v)
                      },
                      expression: "listQuery.searchValue"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.listQuery.order_status,
                        callback: function($$v) {
                          _vm.$set(_vm.listQuery, "order_status", $$v)
                        },
                        expression: "listQuery.order_status"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "全部订单", value: undefined }
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.status_arr, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.text, value: item.value }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleFilter }
                    },
                    [_vm._v("\n          搜索\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "ID",
              prop: "id",
              align: "center",
              width: "80",
              "class-name": _vm.getSortClass("id")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单号", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.order_no))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.goods_money))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "运费" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.deliver_money))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "优惠金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.coupon_price))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "支付金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.need_pay))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.created_at))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "class-name": "status-col", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.status_text))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v("\n          详情\n        ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
          "page-sizes": [10]
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "55%",
            top: "5vh"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "table",
            {
              staticClass: "com-table",
              attrs: { cellpadding: "0", cellspacing: "0", border: "0" }
            },
            [
              _c("tbody", [
                _c("tr", [
                  _c("td", { attrs: { width: "50%" } }, [
                    _vm._v("订单号：" + _vm._s(_vm.temp.order.order_no))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("快递单号：" + _vm._s(_vm.temp.order.express_no)),
                    _c("span")
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("商品总金额：" + _vm._s(_vm.temp.order.goods_money))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("运费：" + _vm._s(_vm.temp.order.deliver_money))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("优惠券金额：" + _vm._s(_vm.temp.order.goods_money))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("实付金额：" + _vm._s(_vm.temp.order.need_pay))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v(
                      "收货地址：" +
                        _vm._s(_vm.temp.order.province) +
                        _vm._s(_vm.temp.order.city) +
                        _vm._s(_vm.temp.order.county) +
                        _vm._s(_vm.temp.order.address)
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("收货人：" + _vm._s(_vm.temp.order.name))]),
                  _vm._v(" "),
                  _c("td", [_vm._v("手机：" + _vm._s(_vm.temp.order.phone))])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("创建时间：" + _vm._s(_vm.temp.order.created_at))
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _vm._v(
                        "状态：" + _vm._s(_vm.temp.order.status_text) + " "
                      ),
                      _vm.temp.order.order_status == 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.payQuery(_vm.temp)
                                }
                              }
                            },
                            [_vm._v("支付查询")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("支付时间：" + _vm._s(_vm.temp.order.pay_time))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("发货时间：" + _vm._s(_vm.temp.order.delivery_time))
                  ])
                ]),
                _vm._v(" "),
                _vm.temp.refund
                  ? _c("tr", [
                      _c("td", [
                        _vm._v(
                          "售后状态：" + _vm._s(_vm.temp.service_status_text)
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "退款申请时间：" + _vm._s(_vm.temp.refund.created_at)
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.temp.refund
                  ? _c("tr", [
                      _c("td", [
                        _vm._v(
                          "快递单号：" + _vm._s(_vm.temp.refund.express_no)
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "快递公司CODE：" +
                            _vm._s(_vm.temp.refund.express_code)
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.temp.refund
                  ? _c("tr", [
                      _c("td", [
                        _vm._v(
                          "商家拒绝原因：" +
                            _vm._s(_vm.temp.refund.reject_reason)
                        )
                      ]),
                      _vm._v(" "),
                      _c("td")
                    ])
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "order-items" },
            _vm._l(_vm.temp.items, function(item) {
              return _c("div", { key: item.id }, [
                item.product
                  ? _c("div", { staticClass: "unit" }, [
                      _c("img", {
                        attrs: {
                          src: item.product.index_img,
                          width: "80",
                          height: "80"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "6px 15px",
                            display: "flex",
                            "justify-content": "center",
                            "flex-wrap": "wrap"
                          }
                        },
                        [
                          _c("div", { staticStyle: { width: "100%" } }, [
                            _vm._v(
                              _vm._s(item.product.title) +
                                "（￥" +
                                _vm._s(item.price) +
                                "）"
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            _vm._l(JSON.parse(item.remark), function(
                              subitem,
                              subkey
                            ) {
                              return _c("span", { key: subkey }, [
                                _vm._v(
                                  " " +
                                    _vm._s(subitem.field) +
                                    ":" +
                                    _vm._s(subitem.value) +
                                    " "
                                )
                              ])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("div", { staticStyle: { width: "100%" } }, [
                            _vm._v("数量：" + _vm._s(item.count))
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("\n        关闭\n      ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }