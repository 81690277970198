"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules**/
var adminBaseRouter = {
  path: '/admin_base',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'admin_base',
  meta: {
    title: '系统设置',
    icon: 'setting',
    roletype: 'admin'
  },
  children: [{
    path: 'sys_config',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys_config/index'));
      });
    },
    name: 'RoleManage',
    meta: {
      title: '基础设置',
      noCache: true,
      authority: 'admin.sys_config.edit',
      roletype: 'admin'
    }
  }]
};
var _default = adminBaseRouter;
exports.default = _default;