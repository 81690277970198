"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

/** When your routing table is too long, you can split it into small modules**/
var authorityRouter = {
  path: '/admin_auth',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'admin',
  meta: {
    title: '权限管理',
    icon: 'lock',
    roletype: 'admin'
  },
  children: [{
    path: 'role',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/role/index'));
      });
    },
    name: 'RoleManage',
    meta: {
      title: '角色管理',
      noCache: true,
      authority: 'admin.role.datagrid',
      roletype: 'admin'
    }
  }, {
    path: 'admin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/index'));
      });
    },
    name: 'AdminManage',
    meta: {
      title: '管理员',
      noCache: true,
      authority: 'admin.admin.datagrid',
      roletype: 'admin'
    }
  }]
};
var _default = authorityRouter;
exports.default = _default;