import { render, staticRenderFns } from "./consumer.vue?vue&type=template&id=014b3476&"
import script from "./consumer.vue?vue&type=script&lang=js&"
export * from "./consumer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\program\\npm\\xky_ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('014b3476')) {
      api.createRecord('014b3476', component.options)
    } else {
      api.reload('014b3476', component.options)
    }
    module.hot.accept("./consumer.vue?vue&type=template&id=014b3476&", function () {
      api.rerender('014b3476', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/shop/consumer.vue"
export default component.exports