"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _usercrud = require("@/api/usercrud");

var _userauth = require("@/utils/userauth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CarriageConfig',
  data: function data() {
    return {
      temp: {
        app_title: '',
        app_logo: '',
        app_desc: '',
        img_data: {}
      },
      uploadParams: {
        api_token: (0, _userauth.getUserToken)()
      },
      uploadUrl: process.env.VUE_APP_BASE_API + 'user/media_uploader',
      rules: {
        app_title: [{
          required: true,
          message: '请输入小程序',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getAppConfig();
  },
  methods: {
    getAppConfig: function getAppConfig() {
      var _this = this;

      this.listLoading = true;
      (0, _usercrud.detail)('app_config', null).then(function (response) {
        // console.log(response);
        _this.temp = response.data;
      });
    },
    updateData: function updateData() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = _this2.temp;
          console.log(tempData);
          (0, _usercrud.edit)('app_config', tempData).then(function () {
            _this2.$notify({
              title: '操作成功',
              message: '修改成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    }
  }
};
exports.default = _default;