var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashboard-container",
      staticStyle: { padding: "32px", background: "#EEE" }
    },
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 40 } },
        [
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c("div", { staticClass: "card-panel" }, [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-people" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "peoples",
                        "class-name": "card-panel-icon"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v("\n            小程序访客\n          ")
                    ]),
                    _vm._v(" "),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.index_data.consumer_count,
                        duration: 2600
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c("div", { staticClass: "card-panel" }, [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-message" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "message",
                        "class-name": "card-panel-icon"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v("\n            小程序数量\n          ")
                    ]),
                    _vm._v(" "),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.index_data.app_count,
                        duration: 3000
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c("div", { staticClass: "card-panel" }, [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "money",
                        "class-name": "card-panel-icon"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v("\n            订单总金额\n          ")
                    ]),
                    _vm._v(" "),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": Number.parseInt(_vm.index_data.order_amount),
                        duration: 3200
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c("div", { staticClass: "card-panel" }, [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-shopping" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "shopping",
                        "class-name": "card-panel-icon"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v("\n            订单数量\n          ")
                    ]),
                    _vm._v(" "),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.index_data.order_count,
                        duration: 3600
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-row", [
        _c("div", {
          ref: "chart",
          style: {
            width: "100%",
            height: "350px",
            background: "#fff",
            padding: "15px"
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }