"use strict";

var _interopRequireDefault = require("D:/program/npm/xky_ui/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.tokenLogin = tokenLogin;
exports.getInfo = getInfo;
exports.logout = logout;

var _request = _interopRequireDefault(require("@/utils/request"));

function login(data) {
  return (0, _request.default)({
    url: 'user/login',
    method: 'post',
    data: data
  });
}

function tokenLogin(data) {
  return (0, _request.default)({
    url: 'user/login',
    method: 'post',
    data: data
  });
}

function getInfo(token) {
  return (0, _request.default)({
    url: 'user/info',
    method: 'get',
    params: {
      api_token: token
    }
  });
}

function logout(token) {
  return (0, _request.default)({
    url: 'user/logout',
    method: 'post',
    params: {
      api_token: token
    }
  });
}