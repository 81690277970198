"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.match");

//
//
//
//
var _default = {
  name: 'MainIndex',
  data: function data() {
    return {};
  },
  watch: {},
  created: function created() {
    // window.addEventListener('storage', this.afterQRScan)
    var url = window.location.href;
    var arr = url.match(/^http(s)?:\/\/(.*?)\//);

    if (!!arr && arr.length > 2 && arr[2] === process.env.VUE_APP_ADMIN_DOMAIN) {
      this.$router.push('/admin_index');
    } else {
      this.$router.push('/user_miniapp/index');
    }

    console.log(arr[2]);
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {// window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {}
};
exports.default = _default;