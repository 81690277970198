var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm.app_info
      ? _c("div", [
          _c(
            "div",
            { staticClass: "app-info" },
            [
              _c(
                "el-row",
                { staticClass: "mt-10" },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "flex flex-cm" }, [
                      _c("div", { staticClass: "head-img" }, [
                        _c("img", { attrs: { src: _vm.app_info.head_img } })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "miniapp" }, [
                        _c("div", { staticClass: "ta-c" }, [
                          _vm._v(_vm._s(_vm.app_info.nick_name))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ta-c lh-30 color-gray" }, [
                          _vm._v(_vm._s(_vm.app_info.principal_name))
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.app_info.verify_type_info == 0
                        ? _c("span", { staticClass: "verify-info" }, [
                            _vm._v("已认证")
                          ])
                        : _c(
                            "span",
                            {
                              staticClass: "verify-info",
                              staticStyle: { color: "#f56" }
                            },
                            [_vm._v("未认证")]
                          )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "full-btn bt-1sd br-1sd",
                          attrs: { href: _vm.auth_url }
                        },
                        [_vm._v("更新授权")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "full-btn bt-1sd",
                          on: { click: _vm.unbind }
                        },
                        [_vm._v("解除授权")]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-500 lh-30 mt-10 b-1sd p-10" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { staticClass: "fw-b", attrs: { span: 6 } }, [
                    _vm._v("状态")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 18 } }, [
                    !_vm.app_program
                      ? _c("span", [_vm._v("未提交代码")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.app_program
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.app_program.status_text))
                        ])
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { staticClass: "fw-b", attrs: { span: 6 } }, [
                    _vm._v("appid")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c("span", [_vm._v(_vm._s(_vm.app_info.app_id))])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _vm.app_program
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "fw-b", attrs: { span: 6 } },
                        [_vm._v("更新时间")]
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _vm._v(_vm._s(_vm.app_program.updated_at))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.app_program
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "fw-b", attrs: { span: 6 } },
                        [_vm._v("版本号")]
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _vm._v(_vm._s(_vm.app_program.program_version))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.app_program && _vm.app_program.reason
                ? _c(
                    "el-row",
                    { staticClass: "a" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "fw-b", attrs: { span: 6 } },
                        [_vm._v("审核信息")]
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.app_program.reason)
                          }
                        })
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.qrcode
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "fw-b", attrs: { span: 6 } },
                        [_vm._v("体验码")]
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c("img", { attrs: { src: _vm.qrcode, width: "150" } })
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.online_qrcode
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "fw-b", attrs: { span: 6 } },
                        [_vm._v("小程序码")]
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c("img", {
                          attrs: { src: _vm.online_qrcode, width: "150" }
                        })
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btns" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "ta-c flex flex-cm", attrs: { span: 24 } },
                    [
                      !_vm.app_program || _vm.app_program.status != 1
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.miniappCommit()
                                }
                              }
                            },
                            [_vm._v("提交代码")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.app_program && _vm.app_program.status == 1
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.undoAudit()
                                }
                              }
                            },
                            [_vm._v("撤回审核")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.app_program && _vm.app_program.status == 0
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.miniappAudit()
                                }
                              }
                            },
                            [_vm._v("提交审核")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.app_program && _vm.app_program.status == 2
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.miniappRelease()
                                }
                              }
                            },
                            [_vm._v("提交发布")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      : _c("div", [
          _c("div", { staticClass: "ta-c", staticStyle: { padding: "15px" } }, [
            _c("img", {
              attrs: { src: _vm.domain + "images/wx_authorize.png" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ta-c" }, [
            _vm._v("\n      您还未授权小程序，请"),
            _c("a", { attrs: { href: _vm.auth_url } }, [_vm._v("点此")]),
            _vm._v("前往授权\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ta-c mt-10" }, [
            _vm._v(
              "\n      若您还没有注册小程序，请前往weixin.qq.com注册微信小程序；\n    "
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }