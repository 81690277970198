"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _usercrud = require("@/api/usercrud");

var _userauth = require("@/utils/userauth");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CarriageConfig',
  data: function data() {
    return {
      temp: {
        mchid: '',
        mchkey: '',
        apiclient_cert: '',
        apiclient_key: ''
      },
      uploadParams: {
        api_token: (0, _userauth.getUserToken)()
      },
      uploadUrl: process.env.VUE_APP_BASE_API + 'user/pem_uploader',
      rules: {
        money: [{
          required: true,
          message: '请输入运费',
          trigger: 'blur'
        }]
      },
      downloadLoading: false,
      domain: process.env.VUE_APP_BASE_API
    };
  },
  created: function created() {
    this.getCarriage();
  },
  methods: {
    getCarriage: function getCarriage() {
      var _this = this;

      this.listLoading = true;
      (0, _usercrud.detail)('pay_config', null).then(function (response) {
        // console.log(response);
        _this.temp = response.data;
      });
    },
    updateData: function updateData() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = _this2.temp;
          (0, _usercrud.edit)('pay_config', tempData).then(function () {
            _this2.$notify({
              title: '操作成功',
              message: '修改成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    uploadCertSuccess: function uploadCertSuccess(response, file, fileList) {
      if (response.code === 20000) {
        this.temp.apiclient_cert = response.data.filename;
      } else {
        (0, _elementUi.Message)({
          message: response.message,
          type: 'error',
          duration: 5 * 1000
        });
      }

      this.$refs.upload1.clearFiles();
    },
    uploadKeySuccess: function uploadKeySuccess(response, file, fileList) {
      if (response.code === 20000) {
        this.temp.apiclient_key = response.data.filename;
      } else {
        (0, _elementUi.Message)({
          message: response.message,
          type: 'error',
          duration: 5 * 1000
        });
      }

      this.$refs.upload2.clearFiles();
    },
    uploadError: function uploadError(err, file, filelist) {
      (0, _elementUi.Message)({
        message: err,
        type: 'error',
        duration: 5 * 1000
      });
    }
  }
};
exports.default = _default;